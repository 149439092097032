<template>
  <div>
    <div class="pititle">
      <el-tabs v-model="titleActive" @tab-click="handleTitle">
        <el-tab-pane label="时间管理" name="timemanage">
          <div>
            <el-date-picker v-model="timeRange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']">
            </el-date-picker>
            <span class="currentstatus">当前状态：{{ statuslabel }}</span>
            <el-button type="primary" class="surebtn" @click="statusConfirm">确认</el-button>
            <el-button @click="clearTimer">取消</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="管理员用户" name="usermanage">
          <div class="zhidingsearch">
            <el-form>
              <div class="litop">
                <el-row>
                  <el-col :span="7">
                    <div class="grid-content bg-purple">
                      <el-form-item label="用户名称">
                        <el-input v-model="name" placeholder="请输入您的用户名称" @keyup.enter.native="search()"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                      <el-form-item label="联系方式">
                        <el-input v-model="telephone" placeholder="请输入您的联系方式" @keyup.enter.native="search()"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!--  <el-col :span="5">
                    <div class="grid-content bg-purple-light">
                        <el-form-item label="地址">
                        <el-input
                            v-model="address"
                            placeholder="地址"
                        ></el-input>
                        </el-form-item>
                    </div> 
                    </el-col>-->
                  <el-col :span="4">
                    <div class="grid-content bg-purple borderstyle">
                      <el-button type="primary" @click="search">查询</el-button>
                      <el-button type="info" @click="qingkong">清空</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
          <div class="piconetn">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="管理员用户" name="first">
                <div class="grid-content bg-purple borderstyle" style="margin-bottom: 5px; float: right">
                  <el-button type="primary" @click="addUser">新增</el-button>
                </div>
                <div class="pitable">
                  <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
                    :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                    <el-table-column label="序号" type="index"> </el-table-column>
                    <el-table-column label="用户名称" prop="name" align="center">
                      <template slot-scope="{ row }">
                        <router-link :to="gotoStep(row)" class="linkto">
                          <span>{{ row.name }}</span>
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="角色" align="center">
                      <template slot-scope="{ row }">
                        <span>{{ row.type_name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="用户权限" align="center">
                      <template slot-scope="{ row }">
                        <div v-for="(item, index) in row.role_type_name" :key="index">
                          <el-tag effect="dark" size="mini" style="margin-left: 5px" v-if="item == '初审'">{{ item
                          }}</el-tag>
                          <el-tag effect="dark" size="mini" type="success" style="margin-left: 5px" v-if="item == '复审'">{{
                            item }}</el-tag>
                          <el-tag effect="dark" size="mini" type="danger" style="margin-left: 5px" v-if="item == '终审'">{{
                            item }}</el-tag>
                        </div>

                      </template>
                      <!-- <el-tag effect="dark" size="mini" type="info">初审</el-tag>
                  <el-tag effect="dark" size="mini" style="margin-left: 5px"
                    >复审</el-tag
                  >
                  <el-tag
                    effect="dark"
                    size="mini"
                    type="danger"
                    style="margin-left: 5px"
                    >终审</el-tag
                  > -->
                    </el-table-column>
                    <el-table-column prop="telephone" label="联系方式" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100" align="center">
                      <template slot-scope="{ row }">
                        <el-button type="text" @click="edituserinfo(row)">编辑</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <div class="pageTotal">
                  <div class="block">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="企业用户" name="second">
                <div class="pitable">
                  <el-table :data="userstabledata" :row-class-name="tabRowClassName" style="width: 100%"
                    :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                    <el-table-column label="序号" type="index"> </el-table-column>
                    <el-table-column label="用户名称" prop="name" align="center">
                      <template slot-scope="{ row }">
                        <router-link :to="gotoStep(row)" class="linkto">
                          <span>{{ row.name }}</span>
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column prop="company" label="企业名称" align="center">
                    </el-table-column>
                    <el-table-column prop="telephone" label="联系方式" align="center">
                    </el-table-column>
                    <!-- <el-table-column
              fixed="right"
              label="操作"
              width="100">
              <template slot-scope="{ row }">                  
                <el-button type="bottom" size="small" :click="gotoStep(row)">编辑</el-button>
              </template>
            </el-table-column> -->
                  </el-table>
                </div>
                <!-- 分页 -->
                <div class="pageTotal">
                  <div class="block">
                    <el-pagination background @size-change="userhandleSizeChange"
                      @current-change="userhandleCurrentChange" :current-page="userpage" layout="prev, pager, next"
                      :total="usertotalCount" :page-size="userlimit">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="重置密码用户审核" name="resetting">
                <div class="pitable">
                  <el-table :data="resetData" :row-class-name="tabRowClassName" style="width: 100%"
                    :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                    <el-table-column label="序号" type="index"> </el-table-column>
                    <el-table-column label="用户名称" prop="username" align="center">
                      <template slot-scope="{ row }">
                        <div><el-tooltip class="item" effect="dark"
                            :content="row.approved == 0 ? '待审核' : row.approved == 2 ? '未通过' : row.approved == 1 ? '通过' : row.approved"
                            placement="right">
                            <span v-if="row.approved == 0"><i class="el-icon-remove-outline"></i></span>
                            <span v-if="row.approved == 2"><i class="el-icon-circle-close"></i></span>
                            <span v-if="row.approved == 1"><i class="el-icon-circle-check"></i></span>
                          </el-tooltip>{{ row.username }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="telephone" label="联系方式" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                      <template slot-scope="{ row }">
                        <el-button type="success" size="mini" @click="operate(row, '1')">通过</el-button>
                        <el-button type="danger" size="mini" @click="operate(row, '2')">不通过</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <div class="pageTotal">
                  <div class="block">
                    <el-pagination background @size-change="userhandleSizeChange"
                      @current-change="userhandleCurrentChange" :current-page="userpage" layout="prev, pager, next"
                      :total="resetCount" :page-size="resetlimit">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <!-- 表格 -->
            <el-dialog :visible.sync="editDialogVisible">
              <el-form :model="editForm" ref="editForm">
                <el-form-item label="用户账户">
                  <el-input v-model="editForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input placeholder="请输入密码" v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="角色">
                  <el-select v-model="editForm.role_id" style="width: 100%" placeholder="请选择">
                    <el-option v-for="item in role_list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="用户权限">
                  <el-select multiple v-model="editForm.role_type" style="width: 100%" placeholder="请选择">
                    <el-option v-for="item in role_type" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-input v-model="editForm.telephone"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog :visible.sync="addDialogVisible">
              <el-form :model="addForm" ref="addForm">
                <el-form-item label="用户账户">
                  <el-input v-model="addForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input placeholder="请输入密码" v-model="addForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="角色">
                  <el-select v-model="addForm.role_id" style="width: 100%" placeholder="请选择">
                    <el-option v-for="item in role_list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="用户权限">
                  <el-select multiple v-model="addForm.role_type" style="width: 100%" placeholder="请选择">
                    <el-option v-for="item in role_type" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-input v-model.number="addForm.telephone"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </el-tab-pane>
        <el-tab-pane label="标签管理" name="labelmanage">
          <div class="grid-content bg-purple borderstyle" style="margin-bottom: 5px; float: right">
            <el-button type="primary" @click="editlabel()">新增</el-button>
          </div>
          <div class="pitable">
            <el-table :data="labelData" :row-class-name="tabRowClassName" style="width: 100%"
              :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column label="标签名称" prop="name" align="center">
                <template slot-scope="{ row }">
                  <router-link :to="gotoStep(row)" class="linkto">
                    <span>{{ row.name }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180px" align="center">
                <template slot-scope="{ row }">
                  <el-button type="text" @click="editlabel(row)">编辑</el-button>
                  <el-button type="danger" @click="delLabel(row.id)" size="mini" icon="el-icon-delete"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="pageTotal">
            <div class="block">
              <el-pagination background @size-change="handleTagSizeChange" @current-change="handleTagCurrentChange"
                :current-page="lapage" layout="prev, pager, next" :total="latotalCount" :page-size="limit">
              </el-pagination>
            </div>
          </div>
          <el-dialog :visible.sync="label_show" title="新增标签" width="30%">
            <el-form>
              <el-form-item label="标签名称" class="label_input">
                <el-input type="text" v-model="labelForm.name"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="label_show = false">取 消</el-button>
              <el-button type="primary" @click="label_sure">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="企业审批申请" name="enterprise">
          <div class="pitable">
            <el-table :data="entData" :row-class-name="tabRowClassName" style="width: 100%"
              :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column label="企业名称" prop="name" align="center" width="240px">
                <template slot-scope="{ row }">
                  <div><el-tooltip class="item" effect="dark"
                      :content="row.approved == null ? '待审核' : row.approved == 0 ? '未通过' : row.approved == 1 ? '通过' : row.approved"
                      placement="right">
                      <span v-if="row.approved == null"><i class="el-icon-remove-outline"></i></span>
                      <span v-if="row.approved == 0"><i class="el-icon-circle-close"></i></span>
                      <span v-if="row.approved == 1"><i class="el-icon-circle-check"></i></span>
                    </el-tooltip>{{ row.name }} <el-tag type="info" size="mini">{{ row.category_name }}</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="企业简称" prop="short_name" align="center">
              </el-table-column>
              <el-table-column label="统一社会信用代码" prop="scn" align="center" width="200px">
              </el-table-column>
              <!-- <el-table-column label="企业类型" prop="category_name" align="center">
              </el-table-column> -->
              <el-table-column label="地址" align="center">
                <template slot-scope="{ row }">
                  <span>{{ row.city1_name + row.city2_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="联系人" prop="contact" align="center">
              </el-table-column>
              <el-table-column label="联系电话" prop="telephone" align="center">
              </el-table-column>
              <el-table-column label="邮箱" prop="email" align="center">
              </el-table-column>
              <!-- <el-table-column label="状态" prop="approved" align="center">
                <template slot-scope="{ row }">
                </template>
              </el-table-column> -->
              <el-table-column fixed="right" label="操作" width="180px" align="center">
                <template slot-scope="{ row }">
                  <el-button link type="text" @click="entDetail(row)">编辑</el-button>
                  <!-- <el-button type="danger" @click="delLabel(row.id)" size="mini" icon="el-icon-delete"></el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="pageTotal">
            <div class="block">
              <el-pagination background @size-change="handleTagSizeChange" @current-change="handleTagCurrentChange"
                :current-page="lapage" layout="prev, pager, next" :total="entCount" :page-size="limit">
              </el-pagination>
            </div>
          </div>
          <el-dialog :visible.sync="ent_show" title="企业注册申请管理" custom-class="entresgister">
            <div class="nametitle">默认登陆账户:{{ entForm.email }}</div>
            <el-form ref="entForm">
              <div class="inline">
                <el-form-item label="企业名称">
                  <el-input type="text" v-model="entForm.name"></el-input>
                </el-form-item>
                <el-form-item label="企业简称">
                  <el-input type="text" v-model="entForm.short_name"></el-input>
                </el-form-item>
              </div>
              <div class="inline">
                <el-form-item label="统一社会信用代码">
                  <el-input type="text" v-model="entForm.scn"></el-input>
                </el-form-item>
                <el-form-item label="企业类型">
                  <el-select v-model="entForm.category_id" style="width: 100%" placeholder="请选择">
                    <el-option v-for="item in entType" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="inline">
                <el-form-item prop="city1_id" label="地级市">
                  <el-select v-model="entForm.city1_id" placeholder="注册所在地级市" @change="cityChange">
                    <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="city2_id" label="区域">
                  <el-select v-model="entForm.city2_id" placeholder="注册所在区或县级市">
                    <el-option v-for="item in countyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="inline">
                <el-form-item label="联系人">
                  <el-input type="text" v-model="entForm.contact"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input type="text" v-model="entForm.telephone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input type="text" v-model="entForm.email"></el-input>
                </el-form-item>
              </div>
              <div class="photo">
                <p>营业执照扫描件</p>
                <el-form-item>
                  <el-image style="width: 100px; height: 100px" :src="entForm.license"></el-image>
                </el-form-item>
              </div>
              <div class="inline">
                <el-form-item label="注册申请审核结果">
                  <el-radio-group v-model="approved">
                    <el-radio label="1">通过</el-radio>
                    <el-radio label="0">拒绝</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="ent_sure">提 交</el-button>
              <el-button @click="ent_show = false">取 消</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="后评估表格" name="evaluationform">
          <evaluationForm ref="evalFormRef"></evaluationForm>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getUserList, roleList, addUser, editUser, userRegisterList, userRegisterEdit } from "@api/user";
import { standardTimeStore, standardTimeList, standLabelAdd, queryLabels, labelEdit, labelDelete, registerList, registerDetail, registerStore } from "@api/project";
import { cityOne, countryData } from "@api/enterprise";
import evaluationForm from "./components/evaluationForm.vue"
import moment from "moment";
export default {
  components: { evaluationForm },
  data() {
    return {
      //选中的表格数据
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      entData: [],
      userlimit: 10,
      role_type: [
        {
          value: "1",
          label: "初审",
        },
        {
          value: "2",
          label: "复审",
        },
        {
          value: "3",
          label: "终审",
        },
      ],
      userpage: 1,
      usertotalCount: 0,
      usertotalPage: 1,
      userstabledata: [],
      token: "",
      telephone: "",
      name: "",
      address: "",
      activeName: "first",
      editDialogVisible: false,
      addDialogVisible: false,
      editForm: {
        id: undefined,
        username: "",
        role_id: "",
        role_type: "",
        telephone: "",
        password: "",
        type: 0
      },
      role_list: [],
      addForm: {
        token: "",
        username: "",
        role_id: "",
        role_type: "",
        telephone: "",
        password: "",
        type: 0
      },
      editIndex: -1,
      titleActive: "timemanage",
      timeRange: [],
      statuslabel: "开放申报",
      isEditTime: false,
      labelData: [],
      labelForm: {
        name: "",
      },
      label_show: false,
      lapage: 1,
      latotalCount: 0,
      latotalPage: 1,
      rowId: "",
      entCount: 0,
      ent_show: false,
      entForm: {
        name: '',
        short_name: '',
        scn: '',
        category_id: '',
        city1_name: '',
        city2_name: '',
        contact: "",
        telephone: "",
        email: "",
        license: "",
      },
      approved: '1',
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      entType: [
        {
          value: 1,
          label: '建设单位'
        },
        {
          value: 2,
          label: '事业单位'
        },
        {
          value: 3,
          label: '勘察设计单位'
        },
        {
          value: 4,
          label: '施工单位'
        },
        {
          value: 5,
          label: '监理单位'
        },
        {
          value: 6,
          label: '技术咨询单位'
        },
        {
          value: 7,
          label: '构件生产厂家'
        },
        {
          value: 8,
          label: '物业管理单位'
        }
      ],
      citys: [],
      countyList: [],
      resetData: [],
      resetCount: 0,
      resetlimit: 10
    };
  },
  mounted() {
    this.getToken();
    this.getRoleList();
    this.queryStandardTime()
  },
  methods: {
    addUser() {
      this.addDialogVisible = true;
    },
    //编辑用户信息
    edituserinfo(row) {
      // console.log(row.id, '打印行信息999000')
      this.rowId = row.id;
      this.editDialogVisible = true;
      this.editForm.username = row.username
      this.editForm.password = row.password
      this.editForm.role_id = row.role_id
      this.editForm.role_type = row.role_type
      this.editForm.telephone = row.telephone
      // this.editForm = Object.assign(row)
      this.editForm.role_id = row.type
    },
    saveAdd() {
      this.addForm.token = this.token;
      addUser(this.addForm).then((res) => {
        this.$message.success("添加成功");
        this.addDialogVisible = false;
        this.getUserList(4, 1);
      });
    },
    saveEdit() {
      // debugger
      this.editForm.token = this.token;
      this.editForm.id = this.rowId;
      if (this.activeName === "first") {
        this.editForm.type = 1
      } else {
        this.editForm.type = 3
      }
      editUser(this.editForm).then((res) => {
        this.$message.success("修改成功");
        this.editDialogVisible = false;
        this.getUserList(4, 1);
      });
    },
    //点击tab事件
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (this.activeName == "second") {
        this.getUserList(4, 3);
      } else if (this.activeName == 'resetting') {
        this.userpage = 1;
        //重置密码列表
        this.resetPasswordData()
      }
    },
    handleTitle(tab, event) {
      this.activeName = "first"
      if (this.titleActive == "labelmanage") {
        this.queryLabelData()
      } else if (this.titleActive == "enterprise") {
        this.enterpriseApplication()
      } else if (this.titleActive == 'evaluationform') {
        this.$refs.evalFormRef.qualitData();
      }
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      this.getUserList(4, 1);
    },
    //查询保存时间数据
    queryStandardTime() {
      let nowDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss"); //当前时间
      standardTimeList({ token: this.$store.state.token, type: 1 }).then((res) => {
        // console.log('打印时间', res.data)
        if (res.data.length > 0) {
          this.statuslabel = '未开放申报'
          this.timeRange.push(res.data[0].start_time, res.data[0].end_time)
          this.isEditTime = true
        } else if (res.data.length == 0 || nowDate > this.timeRange[1]) {
          //再加一个当前时间超过结束时间的时候可以开放申报
          this.statuslabel = '开放申报'
          this.isEditTime = false
        }
      });
    },
    getRoleList() {
      roleList({ token: this.token }).then((res) => {
        this.role_list = res.data;
      });
    },
    //获取管理员用户列表
    getUserList(cl, ty) {
      let param = {};
      // if((this.activeName === 'first' && ty == 1) || (this.activeName === 'first' && ty == null)){
      //   param = {
      //     token: this.token,
      //     page: this.page,
      //     limit: this.limit,
      //     class: cl,
      //     type: ty,
      //   };
      // }else if(){

      // }
      // console.log(cl, ty, '打印入参999')
      if (ty == 1) {
        param = {
          token: this.token,
          page: this.page,
          limit: this.limit,
          class: cl,
          type: ty,
        };
      } else if (this.activeName === 'first' && ty == null) {
        param = {
          token: this.token,
          page: this.page,
          limit: this.limit,
          class: cl,
          type: ty,
        };
      } else if (this.activeName === 'second' && ty == null) {
        param = {
          token: this.token,
          page: this.userpage,
          limit: this.limit,
          class: cl,
          type: ty,
        }
      } else {
        param = {
          token: this.token,
          page: this.userpage,
          limit: this.limit,
          class: cl,
          type: ty,
        };
      }

      if (this.name) {
        param.name = this.name;
      }
      if (this.telephone) {
        param.telephone = this.telephone;
      }
      if (ty == 1) {
        // debugger
        getUserList(param).then((res) => {
          // console.log(res.data, '用户信息')
          this.tableData = res.data;
          this.limit = res.limit;
          this.page = res.page;
          this.totalCount = res.totalCount;
          this.totalPage = res.totalPage;
        });
      } else if (this.activeName === 'first' && ty == null) {
        getUserList(param).then((res) => {
          this.tableData = res.data;
          this.limit = res.limit;
          this.page = res.page;
          this.totalCount = res.totalCount;
          this.totalPage = res.totalPage;
        });
      } else if (this.activeName === 'second' && ty == null) {
        getUserList(param).then((res) => {
          this.userstabledata = res.data;
          this.userlimit = res.limit;
          this.userpage = res.page;
          this.usertotalCount = res.totalCount;
          this.usertotalPage = res.totalPage;
        });
      } else {
        getUserList(param).then((res) => {
          this.userstabledata = res.data;
          this.userlimit = res.limit;
          this.userpage = res.page;
          this.usertotalCount = res.totalCount;
          this.usertotalPage = res.totalPage;
        });
      }
    },
    //获取重置密码列表
    resetPasswordData() {
      let params = {
        token: this.token,
        page: this.userpage,
        limit: this.resetlimit,
        name: this.name
      }
      userRegisterList(params).then((res) => {
        // console.log(res.data, '重置信息')
        this.resetData = res.data.data;
        // this.limit = res.limit;
        this.resetCount = res.data.total;
      });
    },
    //保存状态
    statusConfirm() {
      if (this.timeRange.length == 0) {
        this.$message({
          type: "warning",
          message: "开始和结束时间不能为空",
        });
        return false
      }
      let stateVal = 1
      if (this.statuslabel === '开放申报') {
        stateVal = 1
      } else if (this.statuslabel === '未开放申报') {
        stateVal = 2
      }
      // console.log('打印时间数组', this.timeRange)
      let param = {
        start_time: this.timeRange.length > 0 ? this.timeRange[0] : '',
        end_time: this.timeRange.length > 0 ? this.timeRange[1] : '',
        status: stateVal,
        token: this.$store.state.token,
        type: 1
      }
      standardTimeStore(param).then((res) => {
        // console.log(res, '打印返回的数据')
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        }
      });
    },
    //取消状态
    clearTimer() {
      if (this.statuslabel == "开放申报") {
        this.timeRange = []
      }
    },
    //搜索
    search() {
      this.page = 1;
      this.getUserList(4, null);
    },
    //清空
    qingkong() {
      this.name = ""
      this.telephone = ""
      this.address = ""
      if (this.activeName === 'first') {
        this.getUserList(4, 1);
      } else {
        this.getUserList(4, 3);
      }
    },
    enterpriseApplication() {
      let params = {
        token: this.$store.state.token,
        page: this.lapage,
        limit: this.limit
      }
      registerList(params).then((rec) => {
        // console.log(rec, '企业审批申请打印');
        this.entData = rec.data.data;
        this.entCount = rec.data.total;
      });
    },
    entDetail(row) {
      this.ent_show = true
      this.queryEntDetail(row.id)
      this.getCityOne()
      //市区id查询区县信息
      this.getDistrict(row.city1_id)
    },
    queryEntDetail(id) {
      let params = {
        token: this.$store.state.token,
        id: id
      }
      registerDetail(params).then((rec) => {
        // console.log(rec, '详情信息打印');
        this.entForm = { ...rec.data }
      });
    },
    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getUserList(4, 1);
    },
    userhandleSizeChange(page) {
      this.userlimit = page;
      this.resetlimit = page;
      if (this.activeName == "second") {
        this.getUserList(4, 3);
      } else if (this.activeName == 'resetting') {
        //重置密码列表
        this.resetPasswordData()
      }
    },

    handleCurrentChange(page) {
      this.page = page;
      this.getUserList(4, 1);
    },
    userhandleCurrentChange(page) {
      this.userpage = page;
      // this.getUserList(4, 3);
      if (this.activeName == "second") {
        this.getUserList(4, 3);
      } else if (this.activeName == 'resetting') {
        //重置密码列表
        this.resetPasswordData()
      }
    },
    handleTagSizeChange(page) {
      this.limit = page
      if (this.titleActive == "labelmanage") {
        this.queryLabelData()
      } else if (this.titleActive == "enterprise") {
        this.enterpriseApplication()
      }
    },
    handleTagCurrentChange(page) {
      this.lapage = page
      if (this.titleActive == "labelmanage") {
        this.queryLabelData()
      } else if (this.titleActive == "enterprise") {
        this.enterpriseApplication()
      }
    },
    gotoStep(row) {
      let url = "/user/accontimInfo?id=" + row.id + "&token=" + this.$store.state.token;
      return url;
    },
    //确认提交标签
    label_sure() {
      if (this.labelForm.id === undefined) {
        let data = {
          token: this.$store.state.token,
          name: this.labelForm.name
        }
        standLabelAdd(data).then((rec) => {
          // console.log(rec);
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
            this.label_show = false
          }
        });
      } else if (this.labelForm.id != undefined) {
        let data = {
          token: this.$store.state.token,
          id: this.labelForm.id,
          name: this.labelForm.name
        }
        labelEdit(data).then((rec) => {
          // console.log(rec);
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
            this.label_show = false
          }
        });
      }
    },
    //查询标签列表
    queryLabelData() {
      let params = {
        token: this.$store.state.token,
        page: this.lapage,
        limit: this.limit
      }
      queryLabels(params).then((rec) => {
        // console.log(rec);
        this.lapage = rec.data.current_page
        this.latotalCount = rec.data.total
        this.labelData = rec.data.data
      });
    },
    //修改或新增
    editlabel(row) {
      // console.log(row)
      this.label_show = true
      if (row != undefined) {
        this.labelForm = { ...row }
      } else {
        //新增
        this.labelForm.name = ''
        this.labelForm.id = undefined
      }
    },
    //删除标签
    delLabel(id) {
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          token: this.$store.state.token,
          id: id
        }
        labelDelete(params).then((rec) => {
          // console.log(rec);
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
          }
        });
      });
    },
    getCityOne() {
      cityOne()
        .then((res) => {
          // console.log(res, '获取市区相关的数据');
          this.citys = res.data
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    //市区筛选
    cityChange() {
      this.getDistrict(this.entForm.city1_id)
      this.entForm.city2_id = ""
    },
    getDistrict(pid) {
      let params = {
        parent_id: pid
      }
      countryData(params)
        .then((res) => {
          // console.log(res, '获取市区相关的数据');
          this.countyList = res.data
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    //企业审批修改确认
    ent_sure() {
      this.entForm.token = this.$store.state.token;
      this.entForm.approved = this.approved;
      let data = { ...this.entForm }
      // console.log(data, '入参')
      registerStore(data)
        .then((res) => {
          if (res.code === 200) {
            this.ent_show = false
            this.$message.success(res.message);
            this.enterpriseApplication()
          }
        })
        .catch((err) => {
          //console.log(err)
          this.$message.error(err.message);
        });
    },
    //审批忘记密码
    operate(row, type) {
      // userRegisterEdit
      let data = {
        token: this.$store.state.token,
        approved: type == '1' ? 1 : 2,
        id: row.id,
        user_id: row.user_id
      }
      // console.log(data, '入参')
      this.$confirm(type === '1' ? '确认通过?' : '确认不通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userRegisterEdit(data)
          .then((res) => {
            // console.log(res, '打印')
            if (res.code === 200) {
              this.$message.success(res.message);
            }
          })
          .catch((err) => {
            //console.log(err)
            this.$message.error(err.message);
          });
      }).catch(() => {
        // console.log('取消')
      });
    }
  },
};
</script>
<style scoped>
/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.pitable .el-icon-remove-outline {
  color: #f49917;
}

.pitable .el-icon-circle-close {
  color: #dc3545;
}

.pitable .el-icon-circle-check {
  color: #23bf08;
}

.litop {
  margin-top: 20px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 38px 0;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
}

.linkto>span:hover {
  color: #84b5ec;
}

.currentstatus {
  padding: 0 20px;
  font-size: 14px;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}

.addBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/deep/ .label_input .el-input {
  width: 70%;
  margin-right: 11px;
}

.inconent .label_list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.inconent .label_list .btns {
  margin-right: 8px;
  padding-bottom: 5px;
}

/deep/ .entresgister {
  width: 60%;
  position: relative;
}

.entresgister .nametitle {
  color: #2d2d2d;
  font-size: 14px;
  padding-bottom: 11px;
}

.entresgister .photo {
  position: absolute;
  right: 12%;
  top: 20%;
  width: 300px;
  height: 260px;
  /* border: 1px solid; */
  text-align: center;
}

.entresgister .photo img {
  vertical-align: middle;
  width: 100%;
}

.entresgister .inline {
  display: flex;
}

.entresgister .inline .el-form-item {
  padding-right: 22px;
}

.entresgister .inline .el-form-item .el-input,
.entresgister .inline .el-form-item .el-select {
  width: 300px !important;
}
</style>
