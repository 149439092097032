<template>
    <div class="evaluation">
        <div class="zhidingsearch">
            <el-form>
                <div class="litop">
                    <el-row>
                        <el-col :span="7">
                            <div class="grid-content bg-purple">
                                <el-form-item label="实施状况">
                                    <el-input v-model="name" placeholder="请输入实施状况"
                                        @keyup.enter.native="search()"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="grid-content bg-purple borderstyle">
                                <el-button type="primary" @click="search">查询</el-button>
                                <el-button type="info" @click="qingkong">清空</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <div class="pitable">
            <el-table :data="evalData" :row-class-name="tabRowClassName" style="width: 100%"
                :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div>
                            <el-tree :data="props.row.problem" ref="tree" :props="defaultProps" node-key="id"
                                @node-click="handleNodeClick" @node-drag-start="handleDragStart"
                                @node-drag-enter="handleDragEnter" @node-drag-leave="handleDragLeave"
                                @node-drag-over="handleDragOver" @node-drag-end="handleDragEnd" @node-drop="handleDrop"
                                draggable>
                                <span class="custom-tree-node" slot-scope=" { node, data }">
                                    <span v-if="node.data.type === 1">{{ data.score }} {{ data.name }} {{ data.weight
                                    }}</span>
                                    <span v-if="node.data.type === 2">分值({{ data.score.join('~') }}) {{ data.content }} {{
                                        data.weight }}</span>
                                    <el-button type="text" size="mini" v-if="data.id" @click="() => editNode(node, data)">
                                        <i class="el-icon-edit"></i>
                                    </el-button>
                                </span>
                                <!-- <span class="custom-tree-node show-hide keywords_em" slot-scope="{ node, data }">
                                    <span class="ssss" v-if="filterText != ''" v-html="ruleTitle(node.label)"></span>

                                    <span class="ddd" v-if="filterText == ''" v-text="data.label"></span>
                                    <span style="display:none">
                                        <el-button v-if="data.id !== 1" v-if="data.id != 1" type="text" size="mini"
                                            @click="() => edit(node, data)">
                                            <i class="el-icon-edit"></i>
                                        </el-button>
                                        <el-button type="text" size="mini" @click="() => append(node, data)">
                                            <i class="el-icon-plus"></i>
                                        </el-button>
                                        <el-button type="text" size="mini" @click="() => remove(node, data)">
                                            <i class="el-icon-delete"></i>
                                        </el-button>
                                    </span>
                                </span> -->
                            </el-tree>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="序号" type="index"> </el-table-column>
                <el-table-column label="问卷名" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180px" align="center">
                    <template slot-scope="{ row }">
                        <el-button type="text" @click="editName(row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">
            <div class="block">
                <el-pagination background @size-change="handleTagSizeChange" @current-change="handleTagCurrentChange"
                    :current-page="page" layout="prev, pager, next" :total="entCount" :page-size="limit">
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="qu_show" title="修改" custom-class="entresgister" width="30%">
            <el-form ref="questionForm">
                <el-form-item label="问卷名称">
                    <el-input type="text" v-model="questionForm.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="qu_sure">提 交</el-button>
                <el-button @click="qu_show = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="node_show" title="修改" custom-class="entresgister" width="30%">
            <el-form ref="questionForm">
                <el-form-item label="问卷列表名称" v-if="p_Id === 1">
                    <el-input type="text" v-model="questionForm.issueName"></el-input>
                </el-form-item>
                <el-form-item label="权重" v-if="p_Id === 1">
                    <el-input type="text" v-model="questionForm.weight"></el-input>
                </el-form-item>
                <div v-if="p_Id === 2">
                    <el-form-item label="说明">
                        <el-input type="text" v-model="questionForm.content"></el-input>
                    </el-form-item>
                    <el-form-item label="分值">
                        <el-input type="text" v-model="questionForm.score"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="node_sure">提 交</el-button>
                <el-button @click="node_show = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>
 
<script>
import { qualitList, qualitSave, questionnaireSave } from "../../../../../api/project";
export default {
    components: {},
    data() {
        return {
            evalData: [],
            page: 1,
            entCount: 0,
            limit: 10,
            name: '',
            titleList: ['一级问题名', '评分', '权重'],
            defaultProps: {
                children: 'two_leverl',
                label: 'name'
            },
            questionForm: {
                name: '',
                issueName: '',
                weight: "",
                content: "",
                score: ""
            },
            qu_show: false,
            token: "",
            rowId: "",
            p_Id: "",
            node_show: false,
            sort: 0
        }
    },
    props: {

    },
    mounted() {
        this.token = this.$store.state.token
    },
    methods: {
        qualitData() {
            let params = {
                token: this.token,
                name: this.name,
                page: this.page,
                limit: this.limit
            }
            qualitList(params).then((rec) => {
                // this.evalData = rec.data.data;
                this.evalData = rec.data.data.map(val => ({ ...val, isEdit: false }));
                // console.log(this.evalData, '打印输出');
                this.entCount = rec.data.total;
                this.page = rec.data.current_page;
            });
        },
        handleTagSizeChange(page) {
            this.limit = page
            this.qualitData()
        },
        handleTagCurrentChange(page) {
            this.page = page
            this.qualitData()
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return "warning-row";
            }
        },
        entDetail() {

        },
        search() {
            this.qualitData()
        },
        qingkong() {
            this.name = ''
            this.qualitData()
        },
        //树节点点击
        handleNodeClick(node, data) {
            // console.log(node)
        },
        editName(row) {
            this.qu_show = true
            this.questionForm.name = row.name
            this.rowId = row.id
        },
        //修改节点
        editNode(node, data) {
            // console.log(node.data.type, '打印父节点数据')
            // console.log(node.data.score, '分值')
            this.p_Id = node.data.type
            this.node_show = true
            this.rowId = node.data.id; //重新赋值
            this.questionForm.issueName = node.data.name
            this.questionForm.weight = node.data.weight
            this.questionForm.content = node.data.content
            this.questionForm.score = node.data.score === null ? '' : node.data.score.join(',')
            // console.log(node.data.score,'分值')
        },
        qu_sure() {
            let data = {
                id: this.rowId,
                token: this.token,
                name: this.questionForm.name
            }
            qualitSave(data).then((rec) => {
                // console.log(rec, '打印输出');
                if (rec.code === 200) {
                    this.$message.success(rec.message);
                    this.qualitData()
                    this.qu_show = false;
                }
            });
        },
        handleDragStart(node, ev) {
            console.log('打印节点的层级', node.level);
            this.sort = node.level
        },
        handleDragEnter(draggingNode, dropNode, ev) {
            console.log('tree drag enter: ', dropNode.label);
        },
        handleDragLeave(draggingNode, dropNode, ev) {
            console.log('tree drag leave: ', dropNode.label);
        },
        handleDragOver(draggingNode, dropNode, ev) {
            console.log('tree drag over: ', dropNode.label);
        },
        handleDragEnd(draggingNode, dropNode, dropType, ev) {
            console.log('tree drag end: ', dropNode && dropNode.label, dropType);
        },
        handleDrop(draggingNode, dropNode, dropType, ev) {
            console.log('tree drop: ', dropNode.label, dropType);
        },
        //节点修改提交
        node_sure() {
            let data = {
                id: this.rowId,
                token: this.token,
                name: this.questionForm.issueName,
                weight: this.questionForm.weight,
                content: this.questionForm.content,
                score: this.questionForm.score,
                sort: this.sort
            }
            questionnaireSave(data).then((rec) => {
                // console.log(rec, '打印输出');
                if (rec.code === 200) {
                    this.$message.success(rec.message);
                    this.qualitData()
                    this.node_show = false;
                    this.renderTree();
                }
            });
        }

    }
}
</script>
<style scoped>
.evaluation .pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 38px 0;
}

.pitable .expand_title {
    display: flex;
}

.pitable .items,
.pitable .expand_title .items {
    width: 20%;
    text-align: center;
    padding-bottom: 11px;
}

.pitable .titles {
    width: 20%;
    font-weight: bold;
    text-align: center;
    padding-bottom: 11px;
}
</style>